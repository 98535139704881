import React, { useState, useRef, useEffect } from 'react';
import pfp from '../static/pfp.png';
import ogamewordle from '../static/ogamewordle.png'
import pongai from '../static/pongai.png'
import pythonwebscrape from '../static/PythonWebScrape.png'
import OTools from '../static/OTools.png'
import Twitterbot from '../static/Twitterbot.png'
import CountUpAnimation from '../components/CountUpAnimation';
import emailjs, { send } from '@emailjs/browser';
import '../css/Home.css';

function Home() {
  const [showItems, setShowItems] = useState(false);
  const [listVisible, setListVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showContactForm, setShowContactForm] = useState(false);
  const [emailStatus, setEmailStatus] = useState(null);
  const itemsRef = useRef(null);
  const nextSectionRef = useRef(null);
  const form = useRef();

  const projects = [
    {
      name: "Ogame-Wordle",
      description: "Wordle but you can play 1vs1",
      githubLink: "https://github.com/OskarsTheBest/Ogame-Wordle",
      backgroundImage: ogamewordle
    },
    {
      name: "Pong-Ai",
      description: "Reinforcement Ai playing Pong against you",
      githubLink: "https://github.com/OskarsTheBest/Pong-AI",
      backgroundImage: pongai
    },
    {
      name: "Web Scraper",
      description: "Web Scraper made with python",
      githubLink: "https://github.com/OskarsTheBest/PythonWebScraper",
      backgroundImage: pythonwebscrape
    },
    {
      name: "Tools Site",
      description: "Sum of Converters, project made for an Exam",
      githubLink: "https://github.com/OskarsTheBest/ExamTT2",
      backgroundImage: OTools
    },
    {
      name: "Twitter Download Bot",
      description: "Self made Twitter Download bot which avoids the overpriced X/Twitter api",
      githubLink: "https://twitterbot.otools.site/",
      backgroundImage: Twitterbot
    },
  ];

  const scrollToItems = () => {
    setShowItems(true);
    itemsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToNextSection = () => {
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCounterComplete = () => {
    setListVisible(true);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % projects.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + projects.length) % projects.length);
  };

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
    setEmailStatus(null);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('Portfolio mailto', 'template_bv9jdgs', form.current, 'QVfekU-TWEiv7uTVU')
      .then(
        (result) => {
          console.log('SUCCESS!', result.text);
          setEmailStatus('success');
        },
        (error) => {
          console.log('FAILED...', error.text);
          setEmailStatus('error');
        }
      );
  };
  
  return (
    <div className="App">
      <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-blue-100 to-purple-100">
        <div className="relative mb-6">
          <div className="w-48 h-48 rounded-full bg-gradient-to-r from-blue-400 to-purple-500 shadow-lg"></div>
          <img
            src={pfp}
            alt="Picture of me lol"
            className="absolute inset-0 w-full h-full object-cover rounded-full border-4 border-white"
          />
        </div>
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Oskars Kipens</h1>
        <h3 className="text-xl italic font-semibold text-gray-600 mb-6 tracking-wide border-b-2 border-purple-400 pb-2 transform hover:scale-105 transition-transform duration-300">My Portfolio</h3>
        <button
          onClick={scrollToItems}
          className="animate-bounce text-4xl text-gray-600 hover:text-gray-800 transition-colors duration-300 min-h-96"
        >
          ↓
        </button>
      </div>
      <div ref={itemsRef} className="min-h-screen bg-gradient-to-b from-purple-100 to-blue-100 flex flex-col items-center justify-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">I have been programming since:</h1>
        <div className="bg-white rounded-lg shadow-lg p-8 mt-4">
          <CountUpAnimation
            endValue={2019}
            className="text-6xl font-bold text-blue-600 ml-2"
            onComplete={handleCounterComplete}
          />
          <span className="text-6xl font-bold text-blue-600 ml-2">🚀</span>
        </div>
        {listVisible && (
          <ul className="mt-8 space-y-4 text-xl text-gray-700">
            <li
              className="opacity-0 animate-fadeIn bg-blue-50 rounded-lg shadow-md p-4 transition-all duration-300 hover:shadow-lg hover:scale-105"
              style={{ animationDelay: '0ms', animationFillMode: 'forwards' }}
            >
              🏫 Riga State German Grammar School (2017-2023)
            </li>
            <li
              className="opacity-0 animate-fadeIn bg-purple-50 rounded-lg shadow-md p-4 transition-all duration-300 hover:shadow-lg hover:scale-105"
              style={{ animationDelay: '500ms', animationFillMode: 'forwards' }}
            >
              💻 Computer Science Focus Areas: Python, JavaScript, Robotics.
            </li>
            <li
              className="opacity-0 animate-fadeIn bg-green-50 rounded-lg shadow-md p-4 transition-all duration-300 hover:shadow-lg hover:scale-105"
              style={{ animationDelay: '1000ms', animationFillMode: 'forwards' }}
            >
              🚀 Programming languages: Python, JavaScript, TypeScript, C++
            </li>
            <li
              className="opacity-0 animate-fadeIn bg-yellow-50 rounded-lg shadow-md p-4 transition-all duration-300 hover:shadow-lg hover:scale-105"
              style={{ animationDelay: '1500ms', animationFillMode: 'forwards' }}
            >
              🐙 GitHub: <a href="https://github.com/OskarsTheBest" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">OskarsTheBest</a>
            </li>
            <li
              className="opacity-0 animate-fadeIn bg-pink-50 rounded-lg shadow-md p-4 transition-all duration-300 hover:shadow-lg hover:scale-105"
              style={{ animationDelay: '2000ms', animationFillMode: 'forwards' }}
            >
              🎓 Bachelor of Science in Computer Science, University of Latvia (2023-2027)
            </li>
          </ul>
        )}
        <button
          onClick={scrollToNextSection}
          className="animate-bounce text-4xl text-gray-600 hover:text-gray-800 transition-colors duration-300 mt-8"
        >
          ↓
        </button>
      </div>
      <div ref={nextSectionRef} className="min-h-screen bg-gradient-to-b from-blue-100 to-green-100 flex flex-col items-center justify-center">
        <div className="w-full max-w-4xl h-96 relative overflow-hidden rounded-lg shadow-xl">
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 text-gray-800 font-bold py-2 px-4 rounded-r z-20"
          >
            ←
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 text-gray-800 font-bold py-2 px-4 rounded-l z-20"
          >
            →
          </button>
          {projects.map((project, index) => (
            <div
              key={index}
              className={`absolute inset-0 flex flex-col justify-center items-center p-8 transition-opacity duration-500 ${
                index === currentSlide ? 'opacity-100' : 'opacity-0'
              }`}
              style={{
                backgroundImage: `url(${project.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="z-10 text-white text-center">
                <h2 className="text-4xl font-bold mb-4">{project.name}</h2>
                <p className="text-xl mb-6">{project.description}</p>
                <a
                  href={project.githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  View on GitHub
                </a>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={toggleContactForm}
          className="mt-8 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Let's Collaborate
        </button>
        {showContactForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gradient-to-br from-white to-blue-50 p-8 rounded-lg shadow-xl animate-fadeIn">
              <h2 className="text-2xl font-bold mb-4">Contact Me</h2>
              <form ref={form} onSubmit={sendEmail} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                  <input type="text" id="name" name="from_name" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                  <input type="email" id="email" name="user_email" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea id="message" name="message" rows="4" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
                </div>
                <div className="flex justify-end space-x-2">
                  <button type="button" onClick={toggleContactForm} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">Cancel</button>
                  <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Send</button>
                </div>
              </form>
              {emailStatus === 'success' && (
                <div className="mt-4 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
                  Email sent successfully!
                </div>
              )}
              {emailStatus === 'error' && (
                <div className="mt-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                  Something went wrong. Please contact me directly at oskarskipens2@gmail.com
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
