import React, { useEffect, useState, useRef } from 'react';

function CountUpAnimation({ endValue, duration = 1, className = '', onComplete }) {
  const [currentValue, setCurrentValue] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasStarted) {
          setHasStarted(true);
          observer.disconnect(); // Stop observing after it starts
        }
      },
      {
        threshold: 0.1, // Adjust this threshold as needed
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, [hasStarted]);

  useEffect(() => {
    if (!hasStarted || currentValue >= endValue) return;

    let startValue = currentValue;
    const increment = Math.ceil(endValue / (duration * 60));
    const incrementTime = (duration * 1000) / (endValue / increment);

    const timer = setInterval(() => {
      setCurrentValue(prevValue => {
        const newValue = prevValue + increment;
        if (newValue >= endValue) {
          clearInterval(timer);
          if (onComplete) {
            onComplete(); // Trigger the callback function
          }
          return endValue; // Ensure it ends exactly at the endValue
        }
        return newValue;
      });
    }, incrementTime);

    return () => clearInterval(timer);
  }, [hasStarted, currentValue, endValue, duration, onComplete]);

  return <span ref={elementRef} className={className}>{currentValue}</span>;
}

export default CountUpAnimation;
